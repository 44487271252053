//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false,
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      },
      password: '',
      confirmpassword: ''
    };
  },

  methods: {
    showAlert(message) {
      this.alert.message = message;
      this.alert.show = true;
    },

    async onSetUserPassword() {
      this.alert.show = false;

      try {
        if (!this.password || !this.confirmpassword) {
          this.showAlert('Kindly fill up all the fields');
          return;
        }

        if (this.password !== this.confirmpassword) {
          this.showAlert('Passwords do not match');
          return;
        }

        this.buttonDisabled = true;
        const {
          data
        } = await PatientService.setPassword(this.rowData.email, this.password, this.confirmpassword);

        if (data) {
          this.$emit('show-toast', 'Password updated successfully!', 'success');
        }

        this.password = '';
        this.confirmpassword = '';
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
      } finally {
        this.buttonDisabled = false;
      }
    }

  }
};